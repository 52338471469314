<template>
  <div>
    <div class="table_sty">
      <div class="froms">
        <p class="describe">筛选</p>
        <div class="segmentation"></div>
        <div class="from_submit">
          <el-form label-width="120px" :model="formLabelAlign" class="from">
            
            <el-form-item label="名称：">
              <el-input
                placeholder="请输入名称"
                size="mini"
                class="form-inp"
                v-model="formLabelAlign.name"
              ></el-input>
            </el-form-item>

            <el-form-item label="时间选择：">
              <div>
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formLabelAlign.bengin_time"
                  type="date"
                  placeholder="开始日期"
                  @change="DateChange()"
                >
                </el-date-picker>
                至
                <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="formLabelAlign.end_time"
                  type="date"
                  placeholder="结束日期"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item label="状态：">
              <el-select
                v-model="formLabelAlign.status"
                placeholder="请选择状态"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="search_button">
            <div>
              <el-button
                @click="way_tableData(true)"
                type="primary"
                icon="el-icon-search"
                >查询</el-button
              >
              <el-button @click="on_reset" icon="el-icon-refresh-left"
                >重置</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="tab_sty">
        <div class="tab_top">
          <p>
            <span class="span1">一共{{ total }}个</span>
            <span class="span2">已选择 {{ more_List.length }} 个</span>
            <i class="span_i el-icon-refresh" @click="way_tableData()"
              >数据更新</i
            >
          </p>
          <div>
            <el-button
              type="primary"
              @click="on_pop_addplus()"
              icon="el-icon-plus"
              >新建</el-button
            >
            <el-button @click="on_poprw_flow" icon="el-icon-tickets"
              >创建任务</el-button
            >
            <el-button @click="on_deleteList" icon="el-icon-close"
              >删除</el-button
            >
            <!-- <el-button @click="on_export">导出</el-button> -->
          </div>
        </div>
        <div>
          <el-table
            highlight-current-row
            @current-change="way_singleselect"
            @selection-change="way_moreselect"
            :data="table_List"
            :header-cell-style="{ background: '#f8f8f9' }"
            style="flex: 1"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column prop="name" label="名称">
              <template slot-scope="scope">
                  <el-link
                    class="list_sty"
                    type="primary"
                    @click="on_particulars(scope.row.id)"
                    >{{ scope.row.name }}</el-link
                  >
                </template>
            </el-table-column>
            <el-table-column
              prop="check_times"
              label="检查频率"
            ></el-table-column>

            <el-table-column label="当前任务">
              <template slot-scope="scope">
                <div v-if="scope.row.instance">
                  <div
                    class="schedule"
                    v-for="(item, index) in scope.row.instance"
                    :key="index"
                  >
                    <el-tooltip placement="bottom" effect="light">
                      <div slot="content">
                        开始时间:{{ item.begin_time }}<br />结束时间:{{
                          item.end_time
                        }}
                      </div>
                      <p>{{ item.task_name }}</p>
                    </el-tooltip>
                  </div>
                </div>
                <div v-else>未创建任务</div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="任务完成">
              <template slot-scope="scope">
                <div class="schedule">{{ scope.row.finish_percent }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <el-link
                  class="list_sty"
                  type="primary"
                  @click="on_pop_addplus(scope.row.id)"
                  >修改</el-link
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="paging">
          <el-pagination
            @current-change="way_page"
            :page-size="page_size"
            layout="prev, pager, next, jumper"
            :total="total"
            :current-page="page"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新建修改弹窗 -->
    <el-dialog :title="title" show-close :visible.sync="dialog_show" center>
      <Specialredact
        @way_tableData="way_tableData"
        v-if="dialog_show"
        ref="Specialredact"
        :refbool.sync="dialog_show"
      />
    </el-dialog>

    <el-dialog title="发布任务" show-close :visible.sync="assess_pop" center>
      <el-form ref="formName" label-width="180px" :model="assess_Data">
         
          <el-form-item label="检查日期：" prop="time">
            <el-date-picker
              v-model="assRangetime"
              type="daterange"
              format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item  label="任务表单：" prop="scheme_id"
            :rules="[{ required: true, message: '请选择任务表单' }]">
              <el-select class="form-inp" v-model="assess_Data.scheme_id" placeholder="任务表单：">
                <el-option v-for="item in scheme_list" :key="item.value" :label="item.task_name" :value="item.id">
                </el-option>
              </el-select>
          </el-form-item>

        </el-form>
        <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary"
          >发布</el-button
        >
        <el-button @click="assess_pop = false">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import table_minin from "../../assets/minin/table_minin";
import Specialredact from "../../components/view_redact/Special_redact";
import Personnel from "../../components/Personnel.vue";
// import renwupop from "../../components/renwu_pop";

import {
  inspectionindex,
  inspectionsaveInfo,
  inspectiondelete,
  Workschemeindex,
  WorkInstancesave
} from "../../assets/request/api";
export default {
  name: "Special_inspection",
  components: { Specialredact, Personnel },
  mixins: [table_minin],
  data() {
    return {
      title: "",
      dialog_show: false,
      formLabelAlign: {
        bengin_time: "",
        end_time: "",
        name: "",
        status: "",
        check_user: [],
      },
      //选择模板列表
      //任务
      assess_pop: false,
      assess_Data: {
        source_ids: "",
        task_code:"inspection_recorder",
        begin_time:null,
        end_time:null,
        scheme_id: 0,
      },
      assRangetime:[],
      scheme_list: [],
      task_show: false,
      renwupop_show: false,
      task_From: {
        finish_type: "",
        checkedCities: [],
        task_code: "inspection_recorder",
        begin_time: "",
        end_time: "",
      }, 
  
      pickerOptions: {},
      pickerOptionstask: {},
    };
  },
  created() {
    this.is_enableUrl = inspectionsaveInfo;
    this.url = inspectionindex;
    this.delete_Url = inspectiondelete;
    this.fromData = this.formLabelAlign;
    this.xl_attribute = "status_list";
    this.way_tableData();
  },
  methods: {
    //统计周期结束日期禁用
    DateChangetask(type) {
      if (!type) {
        this.task_From.end_time = "";
      }
      let that = this;
      this.pickerOptionstask = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(date, that.task_From.begin_time);
        },
      };
    },
    on_particulars(id) {
      this.$router.push({
        path: "/assess/inspection_particulars",
        query: {
          id: id,
        },
      });
    },
    //点击任务
    on_poprw_flow() {
      let { more_List } = this;
      if (more_List.length == 0) {
        this.$message({
          message: "请选择后在选择任务",
          type: "warning",
        });
        return;
      }
      let id = more_List.map((item) => item.id).join(",");
      this.on_assess(id);
    },

    on_assess(id, type) {
      this.assess_Data.source_ids = id;
      Workschemeindex({task_code:"inspection_recorder"}).then((res)=>{
        let {data} = res.data;
        this.scheme_list = data;
        this.assess_Data.scheme_id = data[0]['id']; 
      })
      this.assess_pop = true;     
    },

    DateChange(type) {
      if (!type) {
        this.assess_Data.end_time = "";
      }
      let that = this;
      this.pickerOptions = {
        //日期禁用
        disabledDate(date) {
          return that.$tools.Time_todetermine(
            date,
            that.assess_Data.bengin_time
          );
        },
      };
    },
    on_skipaddplus(id) {
      this.$router.push({
        path: "/assess/Special_templatealter",
        query: {
          id: id,
        },
      });
    },
    //绘制模板
    on_template(id) {
      this.$router.push({
        path: "/assess/Special_templatealter",
        query: {
          id,
          page_type: 1,
          request_id: 1,
        },
      });
    },
    on_pop_addplus(id) {
      this.$router.push({
        path: "/assess/Special_inspectionredel",
        query: {
          id,
        },
      });

      // this.dialog_show = true;
      // this.title = id ? "修改" : "新增";
      // this.$nextTick(function () {
      //   this.$refs.Specialredact.way_show(id);
      // });
    },
    on_delete(index, ins) {
      this.formLabelAlign.check_user[index].items.splice(ins, 1);
    },
    on_tabclose(item, index) {
      item.items.splice(index, 1);
    },
    closed_pop() {
      this.check_user = [];
    },
    task_pop() {
      let { more_List } = this;
      if (more_List.length == 0) {
        this.$message({
          message: "请选择后在选择任务",
          type: "warning",
        });
        return;
      }
      this.task_show = true;
    },
    on_preserve() {
      let { more_List } = this;
      let time = this.assRangetime;
      this.assess_Data.begin_time= time[0];
      this.assess_Data.end_time =time[1];
      WorkInstancesave(this.assess_Data).then((res) => {
        if (res.code == 0) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
          });
          this.assess_pop = true;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.describe {
  display: inline-block;
  position: relative;
  padding: 4px;
  font-size: 16px;
  color: #4f7afd;
  font-weight: bold;
  padding-bottom: 10px;
}
.describe:before {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 50%;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #4f7afd;
}
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 200px;
}
.paging {
  text-align: center;
  padding: 10px;
}
.tab_top {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  button {
    margin-right: 10px;
  }
  p {
    display: flex;
    align-items: center;
  }
}
.span1 {
  font-size: 14px;
  color: #000000;
}
.span2 {
  font-size: 14px;
  margin-left: 10px;
  color: #797979;
}
.span_i {
  font-size: 14px;
  color: #797979;
  margin-left: 10px;
  cursor: pointer;
}
.el-divider__text {
  color: #0080ff;
  font-weight: bold;
}
.segmentation {
  height: 1px;
  background-color: #f4f4f4;
  margin-bottom: 20px;
}
.list_sty {
  margin-right: 10px;
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.time-tys {
  display: flex;
  p {
    margin: 0 5px;
    padding-top: 7px;
  }
  .time-inp {
    width: 160px;
  }
}
</style>
